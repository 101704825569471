<template>
  <div>

        <MediaPanel v-if="user" :title="'User documents'" :model="'users'" :has-tag="true" :tags="tags" :model_id="parseInt(user.id)"
                    :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
                    :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
                    :showGuardianVisibility="showGuardianVisibility"
        ></MediaPanel>
  </div>

</template>

<script>

import MediaPanel from "@/components/media/MediaPanel";


export default {
  name: "UserMediaPanel",
  components: {
    MediaPanel
  },
  data() {
    return {
      page: 1,
      limit: 20,
      create: false,
      loading: false,
      loaded: false,

    }
  },
  props: {
    showGuardianVisibility: {
      type: Boolean,
      default() {
        return true
      }
    },
    canEdit: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      required: true
    }
  },


  mounted() {

  }
}
</script>
